import axios from 'axios';
import { User } from '../../Models/user.model';
import Config from '../../config.json';

const apiUrl = Config.VSF_K8S_API_URL;
const apiVersion = Config.VSF_K8S_API_VERSION;

const servicePrefix = `${apiUrl}/auth${apiVersion}`;

enum PasswordTypes {
    CHANGE = 'change',
    RESET = 'reset',
}

export const login = (email: string, password: string, event_code?: string) => {
    const body = {
        email,
        password,
        frontend: 'admin',
    };
    if (event_code) {
        Object.assign(body, { event_code });
    }
    return axios.post(`${servicePrefix}/signin`, body);
};

export const logout = () => {
    return axios.get(`${servicePrefix}/signout`);
};

export const resetPassword = (username: string, event_code?: string) => {
    return axios.post(
        `${servicePrefix}/password`,
        {
            username,
            event_code,
        },
        {
            params: {
                type: PasswordTypes.RESET,
            },
        },
    );
};
export const logoutUser = (user_id: number) => {
    return axios.delete(`${servicePrefix}/session`, {
        params: {
            user_id,
        },
    });
};

export const signup = (user: User, sendEmail: boolean) => {
    return axios.post(`${servicePrefix}/signup`, user, {
        params: {
            send_email: sendEmail,
        },
    });
};

enum AccessTypes {
    ACTION = 'action',
    SCREEN = 'screen',
    ROLE = 'role',
    ALL_ROLES = 'allRoles',
}

export const changeRole = (
    roleId: number,
    user_id: number,
    event_code?: string,
    room_id?: number,
) => {
    return axios.put(
        `${servicePrefix}/access`,
        {
            role_id: roleId,
            event_code,
            room_id,
            user_id,
        },
        {
            params: {
                type: AccessTypes.ROLE,
            },
        },
    );
};

export const changeRoles = (
    roleId: number,
    user_id: number,
    event_code?: string,
    rooms?: number[],
) => {
    return axios.put(
        `${servicePrefix}/access`,
        {
            role_id: roleId,
            event_code,
            rooms,
            user_id,
        },
        {
            params: {
                type: AccessTypes.ROLE,
            },
        },
    );
};

export const getUserRoleList = () => {
    return axios.get(`${servicePrefix}/access`, {
        params: {
            type: AccessTypes.ROLE,
        },
    });
};

// Remove single role of a user
export const removeSingleRole = (roleId: number, user_id: number, event_code: string | null) => {
    return axios.delete(`${servicePrefix}/access`, {
        data: {
            role_id: roleId,
            event_code: event_code ? event_code : null,
            user_id,
        },
        params: {
            type: AccessTypes.ROLE,
        },
    });
};

// Remove all roles of a user or all event roles if an event_code is provided
export const removeAllRoles = (user_id: number, event_code: string | null) => {
    return axios.delete(`${servicePrefix}/access`, {
        data: {
            event_code: event_code ? event_code : null,
            user_id,
        },
        params: {
            type: AccessTypes.ROLE,
        },
    });
};

// Remove all event roles for multiple users and keep the event codes
export const removeAllRolesKeepEventCodes = (user_ids: number[]) => {
    return axios.delete(`${servicePrefix}/access`, {
        data: {
            user_ids: user_ids,
        },
        params: {
            type: AccessTypes.ALL_ROLES,
        },
    });
};

export const getProvidersList = (body: any) => {
    return axios.get(`${servicePrefix}/providers/list`, { params: body });
};

export const putProviderLink = (body: any) => {
    return axios.put(`${servicePrefix}/provider/link`, body);
};
